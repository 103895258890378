class OTF_Owl_Carousel {

    constructor() {
        setTimeout(() => {
            this.initOwlCarousel();
        }, 200);
    }

    initOwlCarousel() {
        $('[data-opal-carousel]').each((index, element) => {
            let listImages = this._getImageThumbnail($(element));
            let $element   = $(element);
            this._setupOwlCarousel($element);
            if ($element.data('thumb')) {
                this._initOwlThumbnails($element, listImages);
            }
        })
    }

    _setupOwlCarousel($element) {
        let items      = $element.data('items') ? $element.data('items') : 3,
            items768   = 3,
            itemMobile = 1;

        if (items < 3) {
            items768 = items;
        }
        let settings = {
            dots              : this._setDefaultBoolean($element.data('dots'), true),
            nav               : this._setDefaultBoolean($element.data('nav'), true),
            //items             : $element.data('items') ? $element.data('items') : 3,
            loop              : this._setDefaultBoolean($element.data('loop'), true),
            center            : this._setDefaultBoolean($element.data('center'), false),
            rewind            : this._setDefaultBoolean($element.data('rewind'), false),
            mouseDrag         : this._setDefaultBoolean($element.data('mousedrag'), true),
            touchDrag         : this._setDefaultBoolean($element.data('touchdrag'), true),
            pullDrag          : this._setDefaultBoolean($element.data('pulldrag'), true),
            freeDrag          : this._setDefaultBoolean($element.data('freedrag'), false),
            margin            : $element.data('margin') ? $element.data('margin') : 0,
            stagePadding      : $element.data('stage-padding') ? $element.data('stage-padding') : 0,
            merge             : this._setDefaultBoolean($element.data('merge'), false),
            mergeFit          : this._setDefaultBoolean($element.data('mergefit'), true),
            autoWidth         : this._setDefaultBoolean($element.data('autowidth'), false),
            startPosition     : $element.data('start-position') ? $element.data('start-position') : 0,
            //rtl               : opalrealesateJS.rtl == 'true',
            //responsive        : {},
            smartSpeed        : $element.data('smart-speed') ? $element.data('smart-speed') : 250,
            fluidSpeed        : this._setDefaultBoolean($element.data('fluid-speed'), false),
            dragEndSpeed      : this._setDefaultBoolean($element.data('dragend-speed'), false),
            animateOut        : $element.data('animate-out') ? $element.data('animate-out') : '',
            animateIn         : $element.data('animate-in') ? $element.data('animate-in') : '',
            autoplay          : this._setDefaultBoolean($element.data('autoplay'), false),
            autoplayTimeout   : $element.data('autoplay-timeout') ? $element.data('autoplay-timeout') : 5000,
            autoplayHoverPause: this._setDefaultBoolean($element.data('autoplay-hover-pause'), false),
            // navText           : opalrealesateJS.navText,
            autoHeight        : this._setDefaultBoolean($element.data('auto-height'), true),
            responsive        : {
                0  : {
                    items: itemMobile,
                    dots : false,
                    stagePadding: 0,
                },
                480: {
                    items: itemMobile,
                    dots : false,
                    stagePadding: 0
                },
                768: {
                    items: items768,
                    stagePadding: 0
                },
                980: {
                    items: items
                }
            }
        };
        if ($element.data('thumb')) {
            settings = {
                ...settings, ...{
                    onChanged: (event) => {
                        this._changedCarouselCB(event, $element, settings)
                    }
                }
            }
        }

        if ($element.data('is-thumb')) {
            settings = {
                ...settings, ...{
                    onInitialized: (event) => {
                        this._thumbnailCarouselLoaded(event, $element);
                    }
                }
            }
        }
        if ($element.data('timeout')) {
            setTimeout(() => {
                $element.owlCarousel(settings);
            }, $element.data('timeout'));
        } else {
            $element.owlCarousel(settings);
        }
    }

    _changedCarouselCB(event, $element, settings) {
        let index = event.item.index;
        if (settings.loop) {
            let itemClone = $element.find('.owl-item.cloned').length / 2;
            if (typeof event.item.index === 'number' && event.item.index != 0) {
                index = event.item.index - itemClone;
                if (index < 0) {
                    index = event.item.count + index;
                }
            }
        }
        let $carouselThumbnail = $element.next();
        if ($carouselThumbnail.hasClass('owl-loaded')) {
            $carouselThumbnail.trigger('to.owl.carousel', index).find('.item').removeClass('selected').each((i, el) => {
                if ($(el).data('index') == index) {
                    $(el).addClass('selected');
                }
            });
        }

    }

    _setDefaultBoolean(value, valueDefault) {
        if (typeof value === 'undefined') {
            return valueDefault;
        } else {
            return value;
        }
    }

    _getImageThumbnail($element) {
        let listImage = [];
        $element.find('img').each((index, img) => {
            listImage.push($(img).attr('src'));
        });
        return listImage;
    }

    _thumbnailCarouselLoaded(event, $element) {
        let $carousel = $element.prev();
        $element.find('.item').on('click', (e) => {
            $carousel.trigger('to.owl.carousel', $(e.currentTarget).data('index'));
        })
    }

    _initOwlThumbnails($element, listImages) {
        let items  = $element.data('thumb-items') ? $element.data('thumb-items') : 5;
        let margin = $element.data('thumb-margin') ? $element.data('thumb-margin') : 0;
        let html   = `<div class="owl-carousel owl-theme owl-carousel-thumbnail" data-is-thumb="true" data-items="${items}" data-dots="false" data-loop="false" data-margin="${margin}" data-nav="false">`;
        let index  = 0;
        for (let image of listImages) {
            html += `<div class="item${index == 0 ? ' selected' : ''}" data-index="${index}">
        <img src="${image}">
    </div>`;
            index++;
        }
        html += `</div>`;

        $element.after(html);
        let $thumb = $element.next();
        this._setupOwlCarousel($thumb);
    }
}

$(document).ready(() => {
    new OTF_Owl_Carousel();
});